import {createMuiTheme} from '@material-ui/core';

export const colors = {
	white: '#ffffff',
	black: '#000000',
	n600: '#101D30',
	g300: '#AFD62D',
	b300: '#2693FF',
	r400: '#D63541',
	o300: '#FFC400',
	jiraBug: '#E63946',
	jiraStory: '#7bc423',
	jiraSpike: '#FAB400',
	jiraTechnical: '#2693FF'
};

export const spacing = {
	xs: '4px',
	s: '8px',
	m: '16px',
	l: '24px',
	xl: '32px'
};

export const appTheme = createMuiTheme({
	palette: {
		primary: {main: colors.n600, contrastText: colors.white},
		secondary: {
			main: colors.g300,
			light: colors.b300,
			contrastText: colors.white
		},
		text: {
			primary: colors.black,
			secondary: colors.g300
		},
		error: {main: colors.r400, contrastText: colors.white}
	},
	typography: {
		fontFamily: [
			'Jost',
			'sans-serif'
		].join(','),
		body1: {
			fontSize: 20
		},
		body2: {
			fontSize: 18,
			fontWeight: 300
		},
		color: {
			secondary: colors.o300,
			textSecondary: colors.o300,
			text: {
				secondary: colors.o300,
				textSecondary: colors.o300
			},
		},
		button: {
			fontFamily: 'Montserrat',
			fontWeight: '600',
			height: '45px',
			textTransform: 'initial'
		},
	}
});
