import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CheckCircle} from "@material-ui/icons";
import {CheckCircleOutline} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {colors} from "../theme/theme";

const useStyles = makeStyles((theme) => ({
    table: {
        "& a": {
            color: colors.b300,
            textDecoration: 'none',
            fontWeight: 400
        },
        "& a:hover": {
            color: colors.n600
        }
    },
    seeMore: {
        marginTop: theme.spacing(2)
    },
}));

export default function RecentVersions() {
    const classes = useStyles();
    const [recentVersions, setRecentVersions] = useState(null);

    useEffect(() => {
        setRecentVersions([
                {
                    "version": "26.0.0",
                    "released": false
                },
                {
                    "version": "25.1.0",
                    "released": true,
                    "releaseDate": "2020-04-22"
                },
                {
                    "version": "25.2.0",
                    "released": true,
                    "releaseDate": "2020-05-04"
                },
                {
                    "version": "21.4.2",
                    "released": true,
                    "releaseDate": "2020-05-04"
                },
                {
                    "version": "24.4.0",
                    "released": false
                },
                {
                    "version": "25.3.0",
                    "released": false
                },
                {
                    "version": "21.5.0",
                    "released": false
                }
            ].sort((a, b) => a.released > b.released || a.releaseDate < b.releaseDate ? 1 : -1)
        );
    }, []);

    return (
        <React.Fragment>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
                Recent Versions
            </Typography>

            {(recentVersions && recentVersions.length > 0) &&
            <>

                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Version</TableCell>
                            <TableCell>Released</TableCell>
                            <TableCell>Released On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recentVersions.map((version) => (
                            <TableRow key={version.id}>
                                <TableCell sortDirection='desc'>
                                    <Link to={"versions/" + version.version}>{version.version}</Link>
                                </TableCell>
                                <TableCell>{version.released}
                                    <FormControlLabel
                                        control={<Checkbox icon={<CheckCircleOutline />} checkedIcon={<CheckCircle />} name="lts" checked={version.released}/>} label=""
                                    />
                                </TableCell>
                                <TableCell>{version.releaseDate}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </>
            }

            <div className={classes.seeMore}>
                <Link color="primary" to="/versions">
                    See more versions
                </Link>
            </div>
        </React.Fragment>
    );
}
