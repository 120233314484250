import React from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function Copyright() {
    return (
        <React.Fragment>
            <Box pt={4}>
                <Typography variant="body2" color="textPrimary" align="center">
                    Fuck it, ship it ™
                </Typography>
            </Box>
        </React.Fragment>
    );
}
