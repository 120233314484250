import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BugReportIcon from '@material-ui/icons/BugReport';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CodeIcon from '@material-ui/icons/Code';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import {colors, spacing} from "../theme/theme";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    table: {
        "& a": {
            color: colors.b300,
            textDecoration: 'none',
            fontWeight: 400
        },
        "& a:hover": {
            color: colors.n600
        }
    },
    cell: {
        lineHeight: '3.5'
    },
    ticketType: {
      marginLeft: spacing.m
    },
    jiraStory: {
        backgroundColor: colors.jiraStory,
        color: colors.white,
        width: '20px',
        height: '20px',
        borderRadius: '2px',
        padding: '3px'
    },
    jiraImprovement: {
        backgroundColor: colors.jiraStory,
        color: colors.white,
        width: '20px',
        height: '20px',
        borderRadius: '2px',
        padding: '3px'
    },
    jiraBug: {
        backgroundColor: colors.jiraBug,
        color: colors.white,
        width: '20px',
        height: '20px',
        borderRadius: '2px',
        padding: '3px'
    },
    jiraTechnical: {
        backgroundColor: colors.jiraTechnical,
        color: colors.white,
        width: '20px',
        height: '20px',
        borderRadius: '2px',
        padding: '3px'
    },
    jiraSpike: {
        backgroundColor: colors.jiraSpike,
        color: colors.white,
        width: '20px',
        height: '20px',
        borderRadius: '2px',
        padding: '3px'
    }
}));

export default function TicketList() {
    const classes = useStyles();
    const params = useParams();
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        const url = 'https://u91auvb37g.execute-api.eu-central-1.amazonaws.com/default/my-second-function?index=0&version=' + params.version;
        axios.get(url)
            .then(response => setTickets(response.data.issues));
    }, [params]);

    function capitalize(str) {
        const low = str.toLowerCase();
        return low.charAt(0).toUpperCase() + low.slice(1);
    }

    return (
        <>
            <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Tickets
            </Typography>

            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Issue</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Release Notes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tickets.map((ticket) => (
                        <TableRow key={ticket.id}>
                            <TableCell className={classes.cell}>
                                <Checkbox color="primary" checked={true}/>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <a href={'https://coyoapp.atlassian.net/browse/' + ticket.id} rel="noopener noreferrer" target="_blank">
                                    {ticket.id}
                                </a>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {{
                                    'STORY': (
                                        <BookmarkIcon className={classes.jiraStory}/>
                                    ),
                                    'IMPROVEMENT': (
                                        <ArrowUpwardIcon className={classes.jiraImprovement}/>
                                    ),
                                    'BUG': (
                                        <BugReportIcon className={classes.jiraBug}/>
                                    ),
                                    'TECHNICAL': (
                                        <CodeIcon className={classes.jiraTechnical}/>
                                    ),
                                    'SPIKE': (
                                        <EmojiObjectsIcon className={classes.jiraSpike}/>
                                    )
                                }[ticket.issueType.toUpperCase()]}
                                <span className={classes.ticketType}>{ capitalize(ticket.issueType) }</span>
                            </TableCell>
                            <TableCell className={classes.cell}>{ticket.releaseNote}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
