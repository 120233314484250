import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import {colors, spacing} from "../theme/theme";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    table: {
        "& a": {
            color: colors.b300,
            textDecoration: 'none',
            fontWeight: 400
        },
        "& a:hover": {
            color: colors.n600
        }
    },
    cell: {
        lineHeight: '3.5'
    },
    changeType: {
        marginLeft: spacing.s
    },
    added: {
        color: colors.jiraStory,
        width: '20px',
        height: '20px',
        borderRadius: '2px'
    },
    changed: {
        color: colors.jiraSpike,
        width: '20px',
        height: '20px',
        borderRadius: '2px'
    },
    deleted: {
        color: colors.jiraBug,
        width: '20px',
        height: '20px',
        borderRadius: '2px'
    },
}));

export default function TranslationList() {
    const classes = useStyles();
    const params = useParams();
    const [version, setVersion] = useState(null);
    const [translations, setTickets] = useState(null);

    useEffect(() => {
                setVersion(params.version);
                setTickets([
                        {
                            "key": "ADMIN.RANDOM.ONE",
                            "changeType": "ADDED",
                            "content": "Bla blubb bli blö"
                        },
                        {
                            "key": "ADMIN.FRONTEND.TWO",
                            "changeType": "CHANGED",
                            "content": "Bla blubb bli"
                        },
                        {
                            "key": "FRONTEND.RANDOM.THREE",
                            "changeType": "DELETED",
                            "content": "Bla blubb"
                        }
                    ]
                );
    }, [params]);

    function capitalize(str) {
        const low = str.toLowerCase();
        return low.charAt(0).toUpperCase() + low.slice(1);
    }

    return (
        (translations && translations.length > 0) &&
        <>

            <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Translations
            </Typography>

            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Key</TableCell>
                        <TableCell>Change</TableCell>
                        <TableCell>Content</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {translations.map((translation) => (
                        <TableRow key={translation.key}>
                            <TableCell className={classes.cell}>
                                <Checkbox color="primary" checked={true}/>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <a href={'https://translate.zanata.org/iteration/view/COYO4//' + version} rel="noopener noreferrer" target="_blank">
                                    {translation.key}
                                </a>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {{
                                    'ADDED': (
                                        <AddIcon className={classes.added}/>
                                    ),
                                    'CHANGED': (
                                        <AutorenewIcon className={classes.changed}/>
                                    ),
                                    'DELETED': (
                                        <CloseIcon className={classes.deleted}/>
                                    )
                                }[translation.changeType]}
                                <span className={classes.changeType}>{ capitalize(translation.changeType) }</span>
                            </TableCell>
                            <TableCell className={classes.cell}>{translation.content}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
