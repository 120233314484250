import {ThemeProvider} from '@material-ui/core';
import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.css';
import {appTheme} from "./theme/theme";
import Dashboard from "./pages/Dashboard";
import ReleaseNotes from "./pages/ReleaseNotes";
import Versions from "./pages/Versions";
import ChangeList from "./pages/ChangeList";
import ReleaseNotePreview from "./pages/ReleaseNotePreview";

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <HashRouter basename="/">
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/versions/:version">
            <ChangeList />
          </Route>
          <Route path="/versions">
            <Versions />
          </Route>
          <Route exact path="/releasenotes/:version">
            <ReleaseNotePreview />
          </Route>
          <Route path="/releasenotes">
            <ReleaseNotes />
          </Route>
        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
