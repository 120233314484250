import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SidebarAndMenu from "../components/SidebarAndMenu";
import Paper from "@material-ui/core/Paper";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Copyright from "../components/Copyright";
import {colors} from "../theme/theme";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    table: {
        "& a": {
            color: colors.b300,
            textDecoration: 'none',
            fontWeight: 400
        },
        "& a:hover": {
            color: colors.n600
        }
    },
    seeMore: {
        marginTop: theme.spacing(2),
    },
    button: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 400
    }
}));

export default function Dashboard() {
    const classes = useStyles();

    const [rows, setRows] = useState([]);

    useEffect(() => {
        axios.get('https://u91auvb37g.execute-api.eu-central-1.amazonaws.com/default/my-function')
            .then(response => setRows(response.data));
    }, []);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <SidebarAndMenu/>
        <main className={classes.content}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography component="h1" variant="h4" color="primary" gutterBottom>
                                <ReceiptIcon /> Release Notes
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Table size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Released On</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell><Link to={"releasenotes/" + row.version}>{row.version}</Link></TableCell>
                                            <TableCell>{row.releaseDate}</TableCell>
                                            <TableCell>
                                                <Button size="small" color="primary" className={classes.button}>
                                                    Show
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
                <Copyright />
            </Container>
        </main>
    </div>
    );
}
