import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SidebarAndMenu from "../components/SidebarAndMenu";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Checkbox from "@material-ui/core/Checkbox";
import {CheckCircle, CheckCircleOutline} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {colors} from "../theme/theme";
import BarChartIcon from "@material-ui/icons/BarChart";
import Copyright from "../components/Copyright";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    table: {
        "& a": {
            color: colors.b300,
            textDecoration: 'none',
            fontWeight: 400
        },
        "& a:hover": {
            color: colors.n600
        }
    }
}));

export default function Dashboard() {
    const classes = useStyles();
    const [versions, setVersions] = useState(null);

    useEffect(() => {
      axios.get('https://u91auvb37g.execute-api.eu-central-1.amazonaws.com/default/my-function')
          .then(response => setVersions(response.data));
    }, []);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <SidebarAndMenu/>

            <main className={classes.content}>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography component="h1" variant="h4" color="primary" gutterBottom>
                                    <BarChartIcon /> Versions
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                {(versions && versions.length > 0) &&
                                <>

                                    <Table size="small" className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Version</TableCell>
                                                <TableCell>Released</TableCell>
                                                <TableCell>Released On</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {versions.sort((a, b) => a.released > b.released || a.releaseDate < b.releaseDate ? 1 : -1).map((version) => (
                                                <TableRow key={version.id}>
                                                    <TableCell sortDirection='desc'>
                                                        <Link to={"versions/" + version.version}>{version.version}</Link>
                                                    </TableCell>
                                                    <TableCell>{version.released}
                                                        <FormControlLabel
                                                            control={<Checkbox icon={<CheckCircleOutline />} checkedIcon={<CheckCircle />} name="lts" checked={version.released}/>} label=""
                                                        />
                                                    </TableCell>
                                                    <TableCell>{version.releaseDate}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright />
                </Container>
            </main>

        </div>
    );
}
