import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SidebarAndMenu from "../components/SidebarAndMenu";
import Paper from "@material-ui/core/Paper";
import WebIcon from "@material-ui/icons/Web";
import Copyright from "../components/Copyright";
import {colors} from "../theme/theme";
import {useParams} from "react-router-dom";
import Logo from "../assets/coyo-logo-on-dark.png";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StarIcon from '@material-ui/icons/Star';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paperHeader: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    paperContent: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(6),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    logo: {
        '& img': {
            width: '250px'
        },
        margin: '200px auto 50px auto'
    },
    logoUpperRight: {
        textAlign: 'right',
        '& img': {
            width: '50px'
        }
    },
    version: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
        color: colors.white,
        textAlign: 'center'
    },
    releaseNoteLabel: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
        color: colors.g300,
        textAlign: 'center',
        marginBottom: '200px'
    },
    pageSubheading: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
        color: colors.g300,
    },
    pageHeading: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
        color: colors.white,
        fontSize: '36px',
        marginBottom: '50px'
    },
    listItem: {
        fontSize: '24px',
        "& a": {
            fontFamily: 'Source Sans Pro',
            fontWeight: 700,
            color: colors.white,
            textDecoration: 'none'
        }
    },
    listIcon: {
        color: colors.white,
        fontSize: '14px'
    },
    listIconImprovement: {
        color: colors.g300,
        fontSize: '14px'
    },
}));

export default function ReleaseNotePreview() {
    const classes = useStyles();
    const params = useParams();
    const [version, setVersion] = useState(null);
    const [tickets, setTickets] = useState(null);

    useEffect(() => {
        setVersion(params.version);
        setTickets([
                {
                    "id": "11381",
                    "issue": "COYOFOUR-11381",
                    "description": "Add option to downgrade existing event admins to participants",
                    "type": "STORY",
                    "status": "DONE",
                    "components": "Events",
                    "labels": "Team Gryffindor",
                    "releaseNotes": "dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lore",
                    "fixVersion": "26.0.0"
                },
                {
                    "id": "11298",
                    "issue": "COYOFOUR-11298",
                    "description": "Some improvement",
                    "type": "IMPROVEMENT",
                    "status": "DONE",
                    "components": "Events",
                    "labels": "Team Gryffindor",
                    "releaseNotes": "justo duo dolores et ea rebum. Stet clita kasd gubergren, no se",
                    "fixVersion": "26.0.0"
                },
                {
                    "id": "10123",
                    "issue": "COYOFOUR-10123",
                    "description": "Some technical stuff",
                    "type": "TECHNICAL",
                    "status": "DONE",
                    "components": "Events",
                    "labels": "Team Gryffindor",
                    "releaseNotes": " kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, con",
                    "fixVersion": "26.0.0"
                },
                {
                    "id": "8872",
                    "issue": "COYOFOUR-8872",
                    "description": "A spike ticket",
                    "type": "SPIKE",
                    "status": "DONE",
                    "components": "Events",
                    "labels": "Team Gryffindor",
                    "releaseNotes": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. ",
                    "fixVersion": "26.0.0"
                },
                {
                    "id": "8213",
                    "issue": "COYOFOUR-8213",
                    "description": "Some technical stuff",
                    "type": "TECHNICAL",
                    "status": "DONE",
                    "components": "Events",
                    "labels": "Team Gryffindor",
                    "releaseNotes": "ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo ",
                    "fixVersion": "26.0.0"
                },
                {
                    "id": "12098",
                    "issue": "COYOFOUR-12098",
                    "description": "Some technical stuff",
                    "type": "TECHNICAL",
                    "status": "DONE",
                    "components": "Events",
                    "labels": "Team Gryffindor",
                    "releaseNotes": "aut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et a takimata",
                    "fixVersion": "26.0.0"
                },
                {
                    "id": "21312",
                    "issue": "COYOFOUR-21312",
                    "description": "A spike ticket",
                    "type": "SPIKE",
                    "status": "DONE",
                    "components": "Events",
                    "labels": "Team Gryffindor",
                    "releaseNotes": "aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo m ipsum dolor sit amet.",
                    "fixVersion": "26.0.0"
                }
            ]
        );
    }, [params]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <SidebarAndMenu/>
        <main className={classes.content}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paperHeader}>
                            <Typography component="h1" variant="h4" color="primary" gutterBottom>
                                <WebIcon /> Release Notes Preview for {version}
                            </Typography>
                        </Paper>
                    </Grid>

                    {(tickets && tickets.length > 0) &&
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.paperContent}>

                                    <div className={classes.logo}>
                                        <img src={Logo} alt="COYO" />
                                    </div>

                                    <Typography component="h1" variant="h4" className={classes.version}>
                                        {version} - minor relase
                                    </Typography>

                                    <Typography component="h1" variant="h5" className={classes.releaseNoteLabel}>
                                        Release notes
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.paperContent}>

                                    <div className={classes.logoUpperRight}>
                                        <img src={Logo} alt="COYO" />
                                    </div>

                                    <List className={classes.list}>
                                        <Typography component="h1" variant="h5" className={classes.pageSubheading}>
                                            Release notes
                                        </Typography>
                                        <Typography component="h1" variant="h5" className={classes.pageHeading}>
                                            Improvements
                                        </Typography>
                                        {tickets.map((ticket) => (
                                            <ListItem className={classes.listItem} key={ticket.id}>
                                                <a href={'https://coyoapp.atlassian.net/browse/' + ticket.issue}
                                                   rel="noopener noreferrer" target="_blank">
                                                    <StarIcon className={classes.listIconImprovement}/> {ticket.issue} - {ticket.releaseNotes}
                                                </a>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.paperContent}>

                                    <div className={classes.logoUpperRight}>
                                        <img src={Logo} alt="COYO" />
                                    </div>

                                    <List className={classes.list}>
                                        <Typography component="h1" variant="h5" className={classes.pageSubheading}>
                                            Release notes
                                        </Typography>
                                        <Typography component="h1" variant="h5" className={classes.pageHeading}>
                                            Technical Improvements
                                        </Typography>
                                        {tickets.slice(1, 5).map((ticket) => (
                                            <ListItem className={classes.listItem} key={ticket.id}>
                                                <a href={'https://coyoapp.atlassian.net/browse/' + ticket.issue}
                                                   rel="noopener noreferrer" target="_blank">
                                                    <FiberManualRecordIcon className={classes.listIcon}/> {ticket.issue} - {ticket.releaseNotes}
                                                </a>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </Grid>
                        </>
                    }
                </Grid>
                <Copyright />
            </Container>
        </main>
    </div>
    );
}
