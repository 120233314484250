import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import RecentVersions from '../components/RecentVersions';
import RecentReleaseNotes from "../components/RecentReleaseNotes";
import SidebarAndMenu from "../components/SidebarAndMenu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Copyright from "../components/Copyright";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

export default function Dashboard() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <SidebarAndMenu/>
            <main className={classes.content}>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Recent RecentVersions */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography component="h1" variant="h4" color="primary" gutterBottom>
                                    <DashboardIcon /> Dashboard
                                </Typography>
                            </Paper>
                        </Grid>
                        {/* Recent RecentVersions */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <RecentVersions />
                            </Paper>
                        </Grid>
                        {/* Recent RecentReleaseNotes */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <RecentReleaseNotes />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright />
                </Container>
            </main>
        </div>
    );
}
