import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {colors} from "../theme/theme";

function createData(id, version, publishedOn) {
    return { id, version, date: publishedOn };
}

const rows = [
    createData(3, '25.3.2', '2 May, 2019', ),
    createData(2, '25.2.1', '6 Apr, 2019', ),
    createData(1, '25.1.0', '16 Mar, 2019', ),
    createData(0, '25.0.0', '12 Feb, 2019', )
];

const useStyles = makeStyles((theme) => ({
    link: {
        "& a": {
            color: colors.b300,
            textDecoration: 'none',
            fontWeight: 400
        },
        "& a:hover": {
            color: colors.n600
        }
    },
    seeMore: {
        marginTop: theme.spacing(2),
    },
    button: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 400
    }
}));

export default function RecentVersions() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Recent Release Notes
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Released On</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell  className={classes.link}>
                                <Link to={"releasenotes/" + row.version}>{row.version}</Link>
                            </TableCell>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>
                                <Button size="small" color="primary" className={classes.button}>
                                    Download
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Link color="primary" to="/releasenotes" className={classes.seeMore}>
                See more release notes
            </Link>
        </React.Fragment>
    );
}
