import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SidebarAndMenu from "../components/SidebarAndMenu";
import Copyright from "../components/Copyright";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {useParams} from "react-router-dom";
import TicketList from "../components/TicketList";
import TranslationList from "../components/TranslationList";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

export default function ChangeList() {
    const classes = useStyles();
    const params = useParams();
    const [version, setVersion] = useState(null);

    useEffect(() => {
        setVersion(params.version);
    }, [params]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <SidebarAndMenu/>
            <main className={classes.content}>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Recent RecentVersions */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography component="h1" variant="h4" color="primary" gutterBottom>
                                    <AssignmentIcon/> Changes for {version}
                                </Typography>
                            </Paper>
                        </Grid>
                        {/* Changed Tickets */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <TicketList/>
                            </Paper>
                        </Grid>
                        {/* Changed Translations */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <TranslationList/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Copyright />
                </Container>
            </main>
        </div>
    );
}
